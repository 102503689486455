import React from "react";

import Layout from "../components/Layout";

import NTAAContact from "../components/NTAAContact";

const NtaaPage = () => {
  return (
    <Layout
      bg="home-bg"
      isDisplayBottomPartner={false}
      isDisplayHeader={false}
      isDisableBottomCta={false}
      isRoundedNone={true}
    >
      <NTAAContact formId="abe-contact-form" />
    </Layout>
  );
};

export default NtaaPage;
